<template>
  <div class="content">
    <BaseH1 :text="'Contact us'" :short="true" />
    <p>
      We’re here to help! If you have any questions or need assistance, please
      reach out to us via email.
    </p>
    <BaseH2 v-if="userId">Important:</BaseH2>
    <p v-if="userId">
      To help us assist you better, please include your User ID in message. This
      will allow us to quickly locate your account and provide the best support.
    </p>
    <p v-if="userId">
      YOUR USER_ID: <b>{{ userId }}</b>
    </p>
    <BaseH2>Email Us At:</BaseH2>
    <p>
      <a href="mailto: contact@lenlo.pl">contact@lenlo.pl</a>
    </p>
    <p>We look forward to assisting you!</p>

    <NavMobile v-if="isMobile" />
  </div>
</template>

<script>
import NavMobile from "@/components/NavMobile.vue";
import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import BaseH2 from "@/components/UI/Base/BaseH2.vue";
import useIsMobile from "@/composables/useIsMobile";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

export default {
  components: { NavMobile, BaseH1, BaseH2 },
  setup() {
    const userStore = useUserStore();
    const { _id: userId } = storeToRefs(userStore);
    const { isMobile } = useIsMobile();
    return { isMobile, userId };
  },
};
</script>

<style scoped>
.content {
  width: 100%;
  padding: 60px 0;

  display: flex;
  flex-flow: column;
  /* align-items: center; */
  row-gap: 20px;
}

@media (max-width: 1200px) {
  .content {
    padding: 30px 0px 80px;
    display: flex;
    flex-flow: column;
    row-gap: 20px;
  }
}
</style>
